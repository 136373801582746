.about {
  @include mq(lg) {
    background-repeat: no-repeat;
  }
  background-image: url("/images/bg.png");
  padding: 50px 0;
  .page__container {
    display: flex;
    flex-direction: column;
    position: relative;
    @include mq(lg) {
      justify-content: flex-end;
      flex-direction: row;
    }
  }
  &__img {
    img {
      width: 100%;
    }
    @include mq(lg) {
      top: -100px;
      left: 0;
      position: absolute;
      img {
        width: auto;
      }
    }
  }
  &__info {
    padding: 15px;
    @include mq(lg) {
      width: 50%;
    }
  }
  &__headline {
    font-size: 55px;
    color: #fff;
    text-align: right;
    margin-bottom: 35px;
    font-weight: 300;
  }
  &__text {
    font-size: 16px;
    line-height: 25px;
    color: #fff;
    text-align: justify;
  }
}
