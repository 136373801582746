.cms {
    .cms-title {
        border-bottom: 1px solid #cecece;
        padding: 0 0 15px 30px;
        margin-bottom: 40px;
        background: url('/images/title-bar-side.png') no-repeat;
        background-position-y: 8px;
        position: relative;

        p {
            font-size: 36px;
            font-weight: 300;
            text-transform: uppercase;
        }

        img {
            position: absolute;
            left: 0;
            bottom: -1px;
        }
    }

    > p {
        color: #3e3e3e;
        margin-bottom: 20px;
        text-align: justify;

        strong {
            font-size: 20px;
        }
    }
}
