.articles {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .article {
        color: #494846;
        display: flex;
        background-color: white;
        margin-bottom: 30px;
        height: 350px;
        overflow: hidden;

        .column {
            width: 50%;
            display: flex;

            &-left {
                padding: 0 20px 0 40px;
                flex-direction: column;
                justify-content: center;
                background: url('../images/article-side.png') no-repeat center;
                background-position-x: 17px;

                .row {
                    display: flex;
                    align-items: flex-end;
                    justify-content: space-between;

                    .date {
                        font-size: 12px;
                    }

                    a {
                        text-decoration: none;
                    }
                }

                .title {
                    margin: 20px 0;
                    font-size: 31px;

                    a {
                        text-decoration: none;
                    }
                }

                > a {
                    margin-top: 30px;
                    color: #494846;
                    text-decoration: none;
                    font-weight: 700;
                }
            }

            &-right {
                justify-content: center;

                img {
                    max-width: 100%;
                    height: auto;
                }
            }
        }
    }
}

@media (max-width: 768px) {
    .articles {
        align-items: center;

        .article {
            flex-direction: column;
            height: 100%;
            max-width: 600px;

            .column {
                width: 100%;

                &-left {
                    padding-top: 20px;
                    padding-bottom: 20px;
                }
            }
        }
    }
}
