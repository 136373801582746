header {
  background-color: #fff;
  padding: 25px 0;
  box-shadow: 0px 0px 13px 3px rgba(0, 0, 0, 0.21);
  border-bottom: 1px solid #ededed;
  margin-bottom: 70px;

  .page__container {
    display: flex;
    align-items: center;
    position: relative;

    .logo {
      font-family: $yanone;
      display: flex;
      align-items: center;
      color: #2d2d2d;
      text-decoration: none;

      img {
        margin-left: 45px;
        margin-right: 40px;
      }

      h1 {
        font-size: 2.5em;
        font-weight: 400;
      }

      @media (max-width: 640px) {
        h1 {
          font-size: 2em;
        }
      }
    }

    @media (max-width: 768px) {
      .logo {
        flex-direction: column;
        text-align: center;
      }
    }

    .top-links {
      position: absolute;
      display: flex;
      right: 0;
      bottom: -80px;
      z-index: -1;

      a {
        display: block;
      }
    }
  }

  @media (max-width: 768px) {
    .page__container {
      justify-content: center;

      .logo {
        flex-direction: column;
        text-align: center;
      }
    }
  }
}
