footer {
    margin-top: 50px;
    padding-top: 50px;
    background-color: white;

    .page__container {
        display: flex;
        flex-direction: column;
    }

    .container {
        display: flex;
        justify-content: space-evenly;

        .divider {
            border-left: 1px solid #d2d4d6;
            margin: 0 auto;
        }

        .column {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            .title {
                width: 100%;
                margin-top: 20px;
                font-size: 18px;
                font-weight: 700;
                padding-bottom: 8px;
                margin-bottom: 25px;
                background: url('../images/footer-title.png') no-repeat bottom;
                background-position-x: 0;
            }

            span:last-child {
                margin-top: 20px;
            }

            img {
                max-width: 100%;
                height: auto;
            }
        }
    }

    .copyright {
        color: #494846;
        margin-top: 20px;
        border-top: 1px solid #d2d4d6;
        text-align: center;
        font-size: 14px;
        padding: 15px 0;

        a {
            color: #494846;
            text-decoration: none;
        }
    }
}

@media (max-width: 1024px) {
    footer {
        .container {
            flex-wrap: wrap;
            justify-content: flex-start;

            .divider {
                &:nth-child(2) {
                    margin: 0 20px;
                }

                &:nth-child(4) {
                    flex-grow: 1;
                    border-left: none;
                }
            }
        }
    }
}

@media (max-width: 640px) {
    footer {
        .container {
            flex-direction: column;

            .divider {
                display: none;
            }
        }
    }
}
