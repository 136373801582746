.icon {
  width: 100%;
  margin: 45px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  &__img {
    cursor: pointer;
  }
}
