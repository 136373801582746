.attachments {
    .title {
        display: block;
        font-size: 1.5rem;
        font-weight: bold;
        margin: 2rem 0 1rem 0;
    }

    &__container {
        display: grid;
        grid-template: auto / repeat(2, minmax(0, 1fr));
        gap: 2rem;

        @include mq(xs) {
            grid-template: auto / repeat(3, minmax(0, 1fr));
        }

        @include mq(md) {
            grid-template: auto / repeat(5, minmax(0, 1fr));
        }
    }

    &__element {
        display: flex;
        flex-direction: column;
        align-content: center;
        justify-content: flex-start;
        max-width: 185px;

        &:link {
            text-decoration: none;
        }

        &:visited {
            text-decoration: none;
        }

        &:hover {
            text-decoration: none;
        }

        &:active {
            text-decoration: none;
        }
    }

    &__icon {
        margin-bottom: 10px;
        max-height: 110px;
        align-self: center;
    }

    &__name {
        color: #3e3e3e;
        text-align: center;
        overflow-wrap: break-word;
        text-decoration: none;
    }
}