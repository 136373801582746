nav.main {
    width: 24%;
    background-color: #ffe58c;
    border-radius: 10px;
    padding: 40px 0;
    box-shadow: 0px 24px 21px 0px rgba(35, 31, 32, 0.21);
    color: #5a4f2a;

    .search {
        margin: 0 30px 30px 30px;
        padding-bottom: 30px;
        border-bottom: 1px solid white;

        input {
            width: 100%;
            padding: 15px 15px 15px 45px;
            border: 1px solid white;
            background: #ffe99c url('/images/search.png') no-repeat center;
            background-position-x: 9px;

            &:focus {
                outline: none;
                box-shadow: 0px 0px 3px 1px rgba(255, 255, 255, 0.75);
            }

            &::placeholder {
                color: #5a4f2abd;
            }
        }
    }

    ul {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        list-style: none;
        margin-left: 20px;

        li {
            width: 100%;

            a {
                display: block;
                color: #5a4f2a;
                text-decoration: none;
                padding: 10px 10px 10px 20px;
                width: 100%;

                &.selected {
                    background-color: #f4f4f6;
                }
            }
        }
    }
}

@media (max-width: 1023px) {
    nav.main {
        display: none;
        position: fixed;
        top: 0;
        transition: opacity 0.3s;
        left: 0;
        width: 100%;
        height: 100vh;
        z-index: 999;
        overflow-y: auto;
        border-radius: 0;

        &.active {
            display: block;
        }

        .search {
            margin: 5px 50px 30px 50px;
        }

        ul {
            margin: 0 50px;
        }
    }
}
