.page {
  background: url('/images/bg.png') no-repeat center center fixed;
  background-size: cover;
  display: flex;
  flex-direction: column;

  .columns {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  &__container {
    margin-left: auto;
    margin-right: auto;
    padding: 0 15px;
    max-width: 1200px;
    width: 100%;
    @include mq(lg) {
      padding: 0;
    }
  }
}

@media (min-width: 1024px) {
  #content {
    width: 71%;
  }
}
