.our-purpose {
    font-weight: 300;
    
    .title {
        font-size: 36px;
        border-bottom: 1px solid #cecece;
        padding: 0 0 15px 30px;
        margin-bottom: 20px;
        background: url('../images/purpose-side.png') no-repeat;
        background-position-y: 8px;
        position: relative;

        img {
            position: absolute;
            left: 0;
            bottom: -1px;
        }
    }

    p {
        line-height: 1.4;
    }
}