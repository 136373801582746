.contact-form {
  .row {
    display: flex;
  }
  .col-sm-6 {
    flex-basis: 100%;
    margin: 5px 15px;
    flex-grow: 1;
    @include mq(md) {
      flex-basis: 44%;
    }
  }
  .col-sm-12 {
    flex-basis: 100%;
    margin: 5px 15px;
  }
  input,
  textarea {
    width: 100%;
    border-radius: 2px;
    padding: 15px;
    border: 1px solid #ededed;
    margin: 5px;
  }
  .contact-btn {
    background-color: $primary;
    padding: 17px;
    margin: 0;
    color: #fff;
    text-align: center;
  }
}
