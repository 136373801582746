.features {
  .page__container {
    display: flex;
    flex-direction: column;
    @include mq(lg) {
      flex-direction: row;
    }
  }
  &__headline {
    font-size: 55px;
    font-weight: 300;
    color: $primary;
    text-align: center;
  }
  &__text {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
  &__image {
    img {
      width: 100%;
      @include mq(lg) {
        width: auto;
      }
    }
  }
  &__box {
    background-image: url("/images/frame.png");
    background-repeat: no-repeat;
    margin: 15px 0;
    display: flex;
    width: 100%;
    height: 144px;
    align-items: center;
    justify-content: space-between;
    padding: 25px;
    background-size: 100%;
    @include mq(md) {
      width: 367px;
      background-size: cover;
    }
    &--right {
      background-image: url("/images/frameright.png");
    }
    p {
      text-align: center;
      text-transform: uppercase;
      font-weight: 400;
      @include mq(md) {
        font-size: 19px;
      }
    }
    img {
      margin: 0 15px;
    }
  }
}
