.cms.cms-stowarzyszenie {
    table {
        border: none !important;
        background-color: rgba($color: #313131, $alpha: 0.05);
        border-radius: 20px;

        tbody {
            tr {
                text-align: center;

                &:first-child {
                    td {
                        width: 100% !important;
                        padding-top: 30px;

                        strong {
                            font-size: 25px;
                            font-weight: 300;
                            text-transform: uppercase;
                        }
                    }
                }

                td {
                    border: none !important;
                    padding-bottom: 40px;

                    p {
                        text-align: center;
                        margin-bottom: 5px;

                        $font-size-multiplier: 1.8;

                        &:nth-child(1) {
                            font-size: 13px * $font-size-multiplier;
                            font-weight: 300;
                        }

                        &:nth-child(2) {
                            font-size: 9px * $font-size-multiplier;
                            font-weight: 300;
                            margin-bottom: 20px;
                        }

                        &:nth-child(3) {
                            font-size: 10px * $font-size-multiplier;
                        }
                    }
                }
            }
        }
    }
}
