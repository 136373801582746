.pagination {
    display: flex;
    flex-wrap: nowrap;
    margin-bottom: 20px;

    li {
        list-style: none;
        font-size: 18px;
        margin-right: 20px;
    }
}
