.cms.cms-partnerzy {
    > div:nth-child(2) {
        display: flex;
        flex-direction: column;

        > div {
            display: flex;
            flex-wrap: wrap;
            margin-top: 20px;

            a {
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-bottom: 40px;
                color: #3e3e3e;
                font-weight: 600;
                text-align: center;
                text-decoration: none;
                width: 50%;
                @include mq(md) {
                    width: calc(100% / 3);
                }
                @include mq(lg) {
                    width: 25%;
                }

                img {
                    max-height: 150px;
                    margin-bottom: 10px;
                }
            }
        }
    }
}
