.cms.cms-kontakt {
    .title {
        width: 100%;
        margin-top: 20px;
        font-size: 18px;
        font-weight: 700;
        padding-bottom: 8px;
        margin-bottom: 25px;
        background: url('../../images/footer-title.png') no-repeat bottom;
        background-position-x: 0;
    }

    .info {
        display: flex;
        justify-content: flex-start;
        margin-bottom: 50px;

        .column {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            &:first-child {
                margin-right: 80px;
            }

            span:last-child {
                margin-top: 20px;
            }

            img {
                max-width: 100%;
                height: auto;
            }
        }
    }

    .contact-form {
        padding: 30px 40px;
        background-color: rgba($color: #313131, $alpha: 0.05);
        border-radius: 20px;

        .title {
            margin-top: 0;
        }

        .alert.alert-success {
            color: #23db0b;
            margin-bottom: 20px;
        }

        .form-group {
            margin-bottom: 10px;

            .text-danger {
                color: red;
                padding: 5px;
            }
        }

        input, textarea {
            margin: 5px 0;
        }

        button {
            display: block;
            color: #5a4f2a;
            background: #ffe58c;
            border: none;
            padding: 20px 60px;
            margin: 20px auto 0 auto;
        }
    }

    img.map {
        height: auto;
        width: 100%;
    }
}
