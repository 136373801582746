@import "~blueimp-gallery/css/blueimp-gallery.min.css";

@import "vars";
@import "mixins";
@import "normalize";
@import "reset";
@import "page";
@import "header";
@import "nav";
@import "icon";
@import "about";
@import "features";
@import "contact";
@import "hamburger";
@import "gallery";
@import "footer";
@import "simple-lightbox";
@import "articles-feed";
@import "purpose";
@import "pagination";
@import "attachments";

@import "cms/cms";
@import "cms/do-pobrania";
@import "cms/partnerzy";
@import "cms/stowarzyszenie";
@import "cms/kontakt";
